




import Component from "vue-class-component"
import { Vue } from "vue-property-decorator"

@Component({
  name: "CalculateMainPage"
})
export default class CalculateMainPage extends Vue {}
